import { AuthFetch, FetchCatchResponse, FetchResponse } from "./index";
import { FileType } from "../types/file";

// export async function getFilesApi(): Promise<FetchResponse<FileType[]>> {
//   try {
//     const { data } = await AuthFetch().get(`/file`);
//     return {
//       data: data.Result,
//       status: true,
//       message: data.Message,
//     };
//   } catch (e) {
//     return FetchCatchResponse(e);
//   }
// }

export async function getFilesApi(
  url: string
): Promise<FetchResponse<FileType[]>> {
  try {
    const { data } = await AuthFetch().get(`/master/drive/ls?path=${url}`);
    return {
      data: data.Result,
      status: true,
      message: data.Message,
    };
  } catch (e) {
    return FetchCatchResponse(e);
  }
}

export async function getFileApi(
  fileId: number
): Promise<FetchResponse<FileType>> {
  try {
    const { data } = await AuthFetch().get(`/file/${fileId}`);
    return {
      data: data.Result,
      status: true,
      message: data.Message,
    };
  } catch (e) {
    return FetchCatchResponse(e);
  }
}

export async function uploadApi(
  file: File,
  url: string
): Promise<FetchResponse<FileType>> {
  try {
    const formData = new FormData();
    formData.append("files", file);
    formData.append("path", url);

    const { data } = await AuthFetch().post(`/master/drive/upload`, formData);
    return {
      data: data.Result,
      status: true,
      message: data.Message,
    };
  } catch (e) {
    return FetchCatchResponse(e);
  }
}
// export async function uploadApi(file: File): Promise<FetchResponse<FileType>> {
//   try {
//     const formData = new FormData();
//     formData.append("file", file);

//     const { data } = await AuthFetch().post(`/file`, formData);
//     return {
//       data: data.Result,
//       status: true,
//       message: data.Message,
//     };
//   } catch (e) {
//     return FetchCatchResponse(e);
//   }
// }

export async function deleteFileApi(
  fileId: number
): Promise<FetchResponse<null>> {
  try {
    const { data } = await AuthFetch().delete(`/file/${fileId}`);
    return {
      data: null,
      status: true,
      message: data.Message,
    };
  } catch (e) {
    return FetchCatchResponse(e);
  }
}
