import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";
import { getFilesApi, getFileApi, deleteFileApi } from "../api/filesApi";
import { FilesStateType } from "../types/file";
import { isAxiosError } from "axios";
import { selectBreadCrumbItems } from "./breadCrumbSlice";
import { UrlConvertor, getParentUrl, parentFinder } from "../utils/helper";

const initialState: FilesStateType = {
  loading: true,
  error: false,
  files: [],
  selectedFile: null,
  selectLoading: false,
};

export const fetchFiles = createAsyncThunk(
  "files/fetchFiles",
  async (_, { getState }) => {
    const { breadCrumb } = getState() as RootState;

    // let url: string;
    // if (breadCrumb.items.length === 1) {
    //   url = "/";
    // } else {
    //   url = getParentUrl(breadCrumb.items);
    // }
    const url = parentFinder(breadCrumb.items);
    const _url = UrlConvertor(url);

    //   const { data } = await getFilesApi(url);

    // async () => {
    const { data } = await getFilesApi(_url);

    return data;
  }
);

// export const fetchFiles = createAsyncThunk("files/fetchFiles", async () => {
//   const { data } = await getFilesApi();

//   return data;
// });

export const getFile = createAsyncThunk(
  "files/getFile",
  async ({ fileId }: { fileId: number }) => {
    const { data } = await getFileApi(fileId);

    return data;
  }
);

export const deleteFile = createAsyncThunk(
  "files/delete",
  async (
    { fileId }: { fileId: number },
    { fulfillWithValue, rejectWithValue }
  ) => {
    const { message, status } = await deleteFileApi(fileId);
    if (status) {
      return message;
    } else {
      return rejectWithValue({ message });
    }
  }
);

const filesSlice = createSlice({
  name: "files",
  initialState: initialState,
  reducers: {
    clearFiles(state) {
      state.files = initialState.files;
      state.loading = initialState.loading;
      state.error = initialState.error;
    },
    selectFile(state, action) {
      state.selectedFile = action.payload.file;
      state.error = initialState.error;
    },
    selectFileById(state, action: { payload: { fileId: number } }) {
      // state.selectedFile =
      // state.files.find((f) => f.Id === action.payload.fileId) || null;
      state.error = initialState.error;
    },
    clearSelectedFile(state) {
      state.selectedFile = null;
    },
    changeSelectingLoading(state, action) {
      state.selectLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFiles.fulfilled, (state, action) => {
        state.files = action.payload;
        state.loading = false;
        state.error = false;
      })
      .addCase(fetchFiles.pending, (state, action) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchFiles.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      });

    builder
      .addCase(getFile.fulfilled, (state, action) => {
        state.selectedFile = action.payload;
        state.selectLoading = false;
        state.error = false;
      })
      .addCase(getFile.pending, (state, action) => {
        state.selectLoading = true;
        state.error = false;
      })
      .addCase(getFile.rejected, (state, action) => {
        state.selectLoading = false;
        state.error = true;
      });

    builder
      .addCase(deleteFile.fulfilled, (state, action) => {
        state.selectedFile = null;
        state.error = false;
      })
      .addCase(deleteFile.pending, (state, action) => {
        state.error = false;
      })
      .addCase(deleteFile.rejected, (state, action) => {
        state.selectedFile = null;
        state.error = true;
      });
  },
});

export const selectLoading = (state: RootState) => state.files.loading;

export const selectSelectingLoading = (state: RootState) =>
  state.files.selectLoading;

export const selectFiles = (state: RootState) => state.files.files;

export const selectSelectedFile = (state: RootState) =>
  state.files.selectedFile;

export const {
  clearFiles,
  selectFile,
  selectFileById,
  changeSelectingLoading,
  clearSelectedFile,
} = filesSlice.actions;

export default filesSlice.reducer;
