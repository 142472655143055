import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";
import { BreadCrumbItems, BreadCrumbStateType } from "../types/breadCrumb";

const initialState: BreadCrumbStateType = {
  loading: true,
  items: [{ title: "My Drive", id: "My Drive" }],
};

// export const fetchPayments = createAsyncThunk(
//   "activity/fetchPayments",
//   async (_, { getState }) => {
//     const { activity } = getState() as RootState;
//     let { pageNumber, pageSize } = activity.body.paginations;
//     const { data } = await getPayments(pageNumber, pageSize);
//     const payments: PaymentType[] = [
//       ...data.activities.map(
//         (ac) =>
//           ({
//             price: ac.Amount,
//             status: ac.Status,
//             signature: ac.Signature,
//             updated_at: ac.UpdatedAt,
//             type: ac.Type,
//           } as PaymentType)
//       ),
//     ];
//     return {
//       payments: payments,
//       pagination: data.pagination,
//     };
//   }
// );

const breadCrumbSlice = createSlice({
  name: "breadCrumb",
  initialState: initialState,
  reducers: {
    updateBreadCrumb(state, action) {
      //   if (action.payload === "My Drive") {
      //     state.items = initialState.items;
      //   } else {
      state.items = [
        ...state.items,
        { title: action.payload, id: Math.random().toString(16).slice(2) },
      ];
      //   }
    },
    breadCrumbRedirect(state, action) {
      const breadCrumbId = action.payload;
      let index;
      for (let i = 0; i < state.items.length; i++) {
        const node = state.items[i];
        if (node.id === breadCrumbId) {
          index = i;
          break;
        }
      }
      state.items.splice((index as number) + 1);
    },
    // clearActivity(state) {
    //   state.body = initialState.body;
    //   state.loading = initialState.loading;
    //   state.error = initialState.error;
    // },
    // changePage(state, action: { payload: { page: number } }) {
    //   state.body.paginations = {
    //     ...state.body.paginations,
    //     pageNumber: action.payload.page,
    //   };
    // },
  },
  extraReducers: (builder) => {
    // builder.addCase(fetchPayments.fulfilled, (state, action) => {
    //   state.body.payments = action.payload.payments;
    //   const { PageNumber, Total } = action.payload.pagination;
    //   state.body.paginations = {
    //     ...state.body.paginations,
    //     pageNumber: PageNumber,
    //     total: Total,
    //   };
    //   state.loading = false;
    //   state.error = false;
    // });
    // builder.addCase(fetchPayments.pending, (state, action) => {
    //   state.loading = true;
    //   state.error = false;
    // });
    // builder.addCase(fetchPayments.rejected, (state, action) => {
    //   state.loading = false;
    //   state.error = true;
    // });
  },
});

// export const selectLoading = (state: RootState) => state.activity.loading;
export const selectBreadCrumbItems = (state: RootState) =>
  state.breadCrumb.items;
export const { updateBreadCrumb, breadCrumbRedirect } = breadCrumbSlice.actions;

export default breadCrumbSlice.reducer;
