import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ContactsCreateDataType, ContactsStateType } from "../types/contacts";
import {
  getUserContactsApi,
  createContactsApi,
  deleteContactsApi,
} from "../api/contactsApi";
import { RootState } from ".";
import { toast } from "react-hot-toast";
import {
  CreateFolderDataType,
  CreateFolderStateType,
} from "../types/createFolder";
import { createFolderApi } from "../api/folderApi";

const initialState: CreateFolderStateType = {
  // contacts: [],
  // selectedContact: null,
  inputValue: "",
  showCreateFolderModal: false,
  loading: false,
  conflict: false,
};

export const getContactsAsync = createAsyncThunk(
  "folder",
  async (_, thunkAPI) => {
    const { data, message, status } = await getUserContactsApi();
    if (!status) {
      return thunkAPI.rejectWithValue({ message });
    }
    return data;
  }
);

// export const createContactAsync = createAsyncThunk(
//   "folder/create",
//   async (formData: ContactsCreateDataType, thunkAPI) => {
//     const { data } = await createContactsApi(formData);
//     return data;
//   }
// );

export const createFolderAsync = createAsyncThunk(
  "folder/create",
  async (formData: CreateFolderDataType, { rejectWithValue, dispatch }) => {
    const { data, message, status } = await createFolderApi(formData);
    if (!status) {
      dispatch(handleShowConflictModal());
    }

    return data;
  }
);

// export const deleteContactAsync = createAsyncThunk(
//   "contacts/delete",
//   async ({ id }: { id: number }, thunkAPI) => {
//     await deleteContactsApi(id);
//     return id;
//   }
// );

const createFolderSlice = createSlice({
  name: "folder",
  initialState,
  reducers: {
    handleHideCreateFolderModal: (state) => {
      state.showCreateFolderModal = false;
    },
    handleShowCreateFolderModal: (state) => {
      state.showCreateFolderModal = true;
    },
    handleShowConflictModal: (state) => {
      state.conflict = true;
    },
    handleHideConflictModal: (state) => {
      state.conflict = false;
    },
    handleChangeInputValue: (state, action) => {
      state.inputValue = action.payload;
    },
    handleClearInputValue: (state) => {
      state.inputValue = "";
    },
    // handleInputValue: (state, action) => {
    //   state.inputValue = action.payload;
    // },
  },
  extraReducers: (builder) => {
    // builder
    //   .addCase(getContactsAsync.pending, (state) => {
    //     state.loading = true;
    //   })
    //   .addCase(getContactsAsync.fulfilled, (state, action) => {
    //     // state.contacts = action.payload;
    //     state.loading = false;
    //   })
    //   .addCase(getContactsAsync.rejected, (state, action) => {
    //     // const message = action.payload.message as { message: string };
    //     toast.error("network error");
    //     state.loading = false;
    //   });

    builder
      .addCase(createFolderAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createFolderAsync.fulfilled, (state, action) => {
        // state.contacts = [...state.contacts, action.payload];
        state.loading = false;
      })
      .addCase(createFolderAsync.rejected, (state, action) => {
        toast.error("network error");
        state.loading = false;
      });

    // builder
    //   .addCase(deleteContactAsync.pending, (state) => {
    //     state.loading = true;
    //   })
    //   .addCase(deleteContactAsync.fulfilled, (state, action) => {
    //     // state.contacts = [
    //     //   ...state.contacts.filter((contact) => contact.Id !== action.payload),
    //     // ];
    //     state.loading = false;
    //   })
    //   .addCase(deleteContactAsync.rejected, (state, action) => {
    //     toast.error("network error");
    //     state.loading = false;
    //   });
  },
});

// export const selectContacts = (state: RootState) => state.contacts.contacts;
// export const selectSelectedContact = (state: RootState) =>
//   state.contacts.selectedContact;
export const selectCreateFolderLoading = (state: RootState) =>
  state.folder.loading;
export const selectShowCreateFolderModal = (state: RootState) =>
  state.folder.showCreateFolderModal;
export const selectShowConflictModal = (state: RootState) =>
  state.folder.conflict;
export const selectInputValue = (state: RootState) => state.folder.inputValue;

export const {
  handleHideCreateFolderModal,
  handleShowCreateFolderModal,
  handleShowConflictModal,
  handleHideConflictModal,
  handleChangeInputValue,
  handleClearInputValue,
} = createFolderSlice.actions;
export default createFolderSlice.reducer;
