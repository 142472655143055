import Credit from "../components/credit";
import Profile from "../components/profile";
import RecentActivity from "../components/recentActivity";
import Settings from "../components/settings";

export default function SettingsProfile() {
  return (
    <div>
      <div className={`text-base text-slate-700`}>
        <Settings />
        <p className={"text-2xl px-7 py-10"}>Personal Account Settings</p>
      </div>
      <div className="flex flex-wrap">
        <div className={"w-full md:w-2/6 "}>
          <Profile />
        </div>
        <div className={"w-full md:w-1/6"}>
          <Credit />
        </div>
        <div className={"w-full md:w-3/6"}>
          <RecentActivity />
        </div>
      </div>
    </div>
  );
}
