import { AuthFetch, FetchCatchResponse, FetchResponse } from "./index";
import { FileType } from "../types/file";
import axios from "axios";

export async function getPhotoApi(file: FileType) {
  try {
    const result = await axios.get(`https://ipfs.io/ipfs/${file.public_cid}`);
    return result;
  } catch (e) {
    return FetchCatchResponse(e);
  }
}
