import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import styles from "./../styles/FileList.module.scss";
import Skeleton from "./skeleton";
import { ReactComponent as EmptyState } from "./../assets/icons/empty-state.svg";
import { useAppDispatch, useAppSelector } from "../hooks";
import { selectLoading as selectProfileLoading } from "../store/profileSlice";
import type { FileType } from "../types/file";
import {
  selectFiles,
  selectLoading,
  getFile,
  selectFile,
} from "../store/filesSlice";
import useShow from "../hooks/useShow";
import { useSearch } from "../providers/SearchProvider";
import File2 from "./file2";

function FileList2() {
  const files = useAppSelector(selectFiles);
  const [filteredFiles, setSearchedFiles] = useState<FileType[]>([]);
  const filesLoading = useAppSelector(selectLoading);
  const profileLoading = useAppSelector(selectProfileLoading);

  const dispatch = useAppDispatch();

  const { value: searchValue } = useSearch();

  useEffect(() => {
    const lookedUpFiles = files?.filter((file) =>
      file.file_name?.includes(searchValue)
    );
    setSearchedFiles(lookedUpFiles);
  }, [dispatch, searchValue, files]);

  //   const { result: searchResult, value: searchValue } = useSearch();
  const [showPdfViewerModal, { show: handleShowPDF, hide: handleHidePDF }] =
    useShow();

  const [showBEModal, { show: handleShowBE, hide: handleHideBE }] = useShow();

  const [showDeleteModal, { show: handleShowDelete, hide: handleHideDelete }] =
    useShow();

  // const openFile = useCallback(
  //   async (file: FileType) => {
  //     if (file.FileType === 0) {
  //       handleShowPDF();
  //       dispatch(getFile({ fileId: file.Id }));
  //     }
  //   },
  //   [dispatch, handleShowPDF]
  // );

  // const deleteFile = useCallback(
  //   async (file: FileType) => {
  //     if (file.FileType === 0) {
  //       dispatch(selectFile({ file }));
  //       handleShowDelete();
  //     }
  //   },
  //   [dispatch, handleShowDelete]
  // );

  return (
    <>
      <>
        <div className={styles.fileList}>
          {!!files?.length && (
            <>
              <div className={classNames(styles.header, "col-name")}>Name</div>
              <div className={classNames(styles.header, "col-size")}>Size</div>
              <div className={classNames(styles.header, "col-lastModified")}>
                Last Modified
              </div>
              <div className={classNames(styles.header, "col-action")}></div>
            </>
          )}

          {(filesLoading || profileLoading) && (
            <>
              {Array.from(Array(24).keys()).map((i) => (
                <div
                  key={`file-skeleton-${i}`}
                  className="flex gap-2 py-4 px-2 h-s"
                >
                  {(i + 1) % 4 === 1 && (
                    <>
                      <Skeleton width={30} height={30} />
                      <Skeleton width={300} height={30} />
                    </>
                  )}
                  {(i + 1) % 4 === 2 && (
                    <>
                      <Skeleton width={150} height={30} />
                    </>
                  )}
                  {(i + 1) % 4 === 3 && (
                    <>
                      <Skeleton width={150} height={30} />
                    </>
                  )}
                  {(i + 1) % 4 === 0 && (
                    <>
                      <Skeleton width={30} height={30} />
                      <Skeleton width={30} height={30} />
                    </>
                  )}
                </div>
              ))}
            </>
          )}
          {!filesLoading &&
            !profileLoading &&
            filteredFiles?.map((file) => (
              <File2
                key={`file-${file._id}`}
                file={file}
                extension={file.extension}
                name={file.file_name}
                is_folder={file.is_folder}
                // onOpenFile={openFile}
                // onDeleteFile={deleteFile}
                onOpenError={handleShowBE}
                size={file.size_byte || 0}
                createdAt={file.created_at as number}
              />
            ))}
        </div>
        {!files?.length && !filesLoading && (
          <div className={styles.empty}>
            <EmptyState />
            <p className="text-2xl text-slate-300 mt-4 font-medium">
              Drop files here
            </p>
            <p className="text-sm text-slate-300">
              Or use “Upload file” button.
            </p>
          </div>
        )}
      </>
    </>
  );
}

export default FileList2;
