import createKeccakHash from "keccak";
import { Workflow } from "../types/workflow";
import { BreadCrumbItems } from "../types/breadCrumb";

export function calcTextWidth(text: string, font: string) {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  if (!context) return 0;
  context.font = font;
  const { width } = context.measureText(text);
  return width;
}

export function toRealAddress(address: string) {
  address = address.toLowerCase().replace("0x", "");
  var hash = createKeccakHash("keccak256").update(address).digest("hex");
  var ret = "0x";

  for (var i = 0; i < address.length; i++) {
    if (parseInt(hash[i], 16) >= 8) {
      ret += address[i].toUpperCase();
    } else {
      ret += address[i];
    }
  }

  return ret;
}

export const getStorage = (key: string, defaultValue: any = "null"): string => {
  return JSON.parse(localStorage.getItem(key) || defaultValue);
};

export const setStorage = (key: string, value: any): string => {
  const stringifyValue = JSON.stringify(value);
  localStorage.setItem(key, stringifyValue);
  return stringifyValue;
};

export const removeStorage = (key: string): void => {
  localStorage.removeItem(key);
};

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

const dateLocale = {
  en: {
    month_names: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    month_names_short: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
};
export function dateFormat(date: Date, withoutDate?: boolean) {
  return `${dateLocale["en"].month_names_short[date.getMonth()]}${
    withoutDate ? "" : " " + date.getDate()
  }, ${date.getFullYear()}`;
}

export function convertDate(date: string) {
  const arr = date.split(/[- T :]/);
  return new Date(
    // @ts-ignore
    arr[0],
    // @ts-ignore
    arr[1] - 1,
    arr[2] || null
  );
}

// a little function to help us with reordering the result
export const dragReorder = <T>(
  list: Array<T>,
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const checkContactsInWorkflowSteps = (workflow: Workflow): boolean => {
  const check =
    workflow.WorkFlowSteps.length > 0 &&
    workflow.WorkFlowSteps.every((step) => step.ContactId !== 0);
  return check;
};

export const checkSignersInWorkflowSteps = (workflow: Workflow): boolean => {
  const signers = workflow.WorkFlowSteps.filter(
    (step) => step.ContactId !== 0 && step.ActionType === 0
  );
  return signers.length > 0;
};

export const workflowProgress = (
  workflow: Workflow | null
): { total: number; done: number; isDone: boolean } => {
  if (workflow === null) {
    return {
      total: 0,
      done: 0,
      isDone: false,
    };
  }

  const signers = workflow.WorkFlowSteps.filter(
    (step) => step.ContactId !== 0 && step.ActionType === 0
  );

  const signedSteps = signers.filter((step) => step.ActionStatus === 2);

  return {
    total: signers.length,
    done: signedSteps.length,
    isDone: signers.length === signedSteps.length,
  };
};

export const getMousePositions = (event: React.MouseEvent<HTMLDivElement>) => {
  const target = event.target as HTMLDivElement;
  const currentTarget = event.currentTarget;
  const monitor_width = target.offsetWidth || currentTarget.offsetWidth;
  const monitor_height = target.offsetHeight || currentTarget.offsetHeight;

  const { left, top } = event.currentTarget.getBoundingClientRect();

  const x = event.clientX - left;
  const y = monitor_height - (event.clientY - top);

  const leftPer = +((x / monitor_width) * 100).toFixed(2);
  const bottomPer = +((y / monitor_height) * 100).toFixed(2);
  return { leftPer, bottomPer };
};

export const getParentUrl = (breadCrumbItems: BreadCrumbItems[]) => {
  let url = "";
  for (let i = 1; i < breadCrumbItems.length; i++) {
    url = url + `/${breadCrumbItems[i]?.title}`;
  }
  return url;
};

export const parentFinder = (breadCrumbItems: BreadCrumbItems[]) => {
  let url: string;
  if (breadCrumbItems.length === 1) {
    url = "/";
  } else {
    url = getParentUrl(breadCrumbItems);
  }
  return url;
};

export const UrlConvertor = (url: string) => {
  return url.replaceAll("/", "%2F");
};

export const dateConvertor = (date: number) => {
  return new Date(date * 1000).toLocaleDateString("en-us", {
    // weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};
