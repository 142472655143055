import { configureStore } from "@reduxjs/toolkit";
import networksReducer from "./networksSlice";
import profileReducer from "./profileSlice";
import activityReducer from "./activitySlice";
import filesReducer from "./filesSlice";
import contactsReducer from "./contactsSlice";
import workflowReducer from "./workflowSlice";
import breadCrumbSlice from "./breadCrumbSlice";
import createFolderSlice from "./createFolderSlice";
import accessKeySlice from "./accessKeySlice";

export const store = configureStore({
  reducer: {
    networks: networksReducer,
    profile: profileReducer,
    activity: activityReducer,
    files: filesReducer,
    contacts: contactsReducer,
    workflow: workflowReducer,
    breadCrumb: breadCrumbSlice,
    folder: createFolderSlice,
    accessKey: accessKeySlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
