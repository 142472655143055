import { useRef, useState } from "react";
import styles from "./../styles/File.module.scss";
import { ReactComponent as Export } from "./../assets/icons/export.svg";
import { ReactComponent as CopyToClipboardIcon } from "./../assets/icons/copy.svg";
import IconButton from "./iconButton";
import classNames from "classnames";
import FileIcon, { FileTypeType, extToType } from "./fileIcon";
import { humanizeSize } from "../utils/file";
import { FileType } from "../types/file";
import { useAppDispatch } from "../hooks";
import Highlighter from "react-highlight-words";
import { useSearch } from "../providers/SearchProvider";
import { updateBreadCrumb } from "../store/breadCrumbSlice";
import { fetchFiles } from "../store/filesSlice";
import { dateConvertor } from "../utils/helper";
import { getExportApi } from "../api/exportApi";
import { toast } from "react-hot-toast";
import { PhotoModal } from "./modals/photoModal";

export type FileProps = {
  is_folder: boolean;
  name: string;
  extension: string | null;
  size: number;
  file: FileType;
  createdAt: number;
  onOpenFile?: (file: FileType) => void;
  onDeleteFile?: (file: FileType) => void;
  onOpenError?: () => void;
};

function File2({
  name,
  is_folder,
  extension,
  size,
  file,
  createdAt,
  onOpenFile,
  onOpenError,
  onDeleteFile,
}: FileProps) {
  const ref = useRef(null);
  const dispatch = useAppDispatch();
  const { value: searchValue } = useSearch();
  const [showPhoto, setShowPhoto] = useState<boolean>(false);
  const [PhotoLoading, setPhotoLoading] = useState<boolean>(false);

  function handleFile(file: FileType, e: any) {
    e.preventDefault();
    if (file.is_folder) {
      dispatch(updateBreadCrumb(file.file_name));
      dispatch(fetchFiles());
    }
    if (
      extToType(
        file.extension?.split(".")[0] ||
          file.file_name.split(".")[1] ||
          file.content_type?.split("/")[1] ||
          ""
      ) === "photo"
    ) {
      setPhotoLoading(true);
      setShowPhoto(true);
    }
  }

  const handleExport = (file: FileType) => {
    getExportApi(file);
  };

  const handleCopyToClipboard = (file: FileType) => {
    if (file.is_folder) {
      return;
    } else
      navigator.clipboard.writeText(`https://ipfs.io/ipfs/${file.public_cid}`);
    toast.success("Copied");
  };

  return (
    <div
      className={classNames(styles.file)}
      ref={ref}
      onDoubleClick={(e) => handleFile(file, e)}
    >
      <div className={classNames(styles.name, "col-name")}>
        <FileIcon
          type={
            is_folder
              ? "folder"
              : (extToType(
                  extension?.split(".")[0] ||
                    file.file_name.split(".")[1] ||
                    file.content_type?.split("/")[1] ||
                    ""
                ) as FileTypeType)
          }
        />
        <p>
          <Highlighter
            highlightClassName="YourHighlightClass"
            searchWords={[searchValue]}
            autoEscape={true}
            textToHighlight={name}
          />
        </p>
      </div>
      <div className={classNames(styles.size, "col-size")}>
        {size !== 0
          ? humanizeSize(Number(size), { fractionDigits: 2 }).join("B")
          : ""}
      </div>
      <div className={classNames(styles.date, "col-lastModified")}>
        {dateConvertor(createdAt)}
      </div>
      <div className={classNames(styles.action, "col-action")}>
        <div className="flex gap-2">
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleCopyToClipboard(file);
            }}
          >
            <CopyToClipboardIcon className="w-7 h-7" />
          </IconButton>

          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              onOpenFile && onOpenFile(file);
            }}
          >
            <Export className="w-6 h-6" onClick={() => handleExport(file)} />
          </IconButton>
        </div>
      </div>
      {showPhoto && (
        <PhotoModal
          show={true}
          onClose={() => {
            setShowPhoto(false);
          }}
          file={file}
          setPhotoLoading={setPhotoLoading}
          photoLoading={PhotoLoading}
        />
      )}
    </div>
  );
}

export default File2;
