import Modal, { ModalContainerProps, ModalKeyDelete } from "./modal";
import styles from "./../../styles/TopUpModal.module.scss";
import Button from "../button";
import { deleteFile, selectSelectedFile } from "../../store/filesSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { toast } from "react-hot-toast";
import { deleteApiKey, fetchApiKeys } from "../../store/accessKeySlice";

function KeyDeleteModal({
  show,
  onClose,
  onCallback,
  keyId,
}: ModalKeyDelete & { onCallback?: () => void }) {
  const file = useAppSelector(selectSelectedFile);
  const dispatch = useAppDispatch();

  return (
    <Modal
      title={"Delete a secret key"}
      bodyClassName={styles.body}
      show={show}
      onClose={onClose}
    >
      <div className="flex justify-center py-4 text-primary-500 flex-col gap-4">
        <div className="flex w-full">Are you sure to delete this file?</div>
        <div className="flex w-full justify-center">
          <Button
            withLoading
            color="danger"
            onClick={() =>
              dispatch(deleteApiKey({ keyId })).then((action) => {
                onClose();
                if (action.type === "access_key/deleteApiKey/fulfilled") {
                  dispatch(fetchApiKeys());
                  toast.success(action.payload.Message as string);
                }
                if (action.type === "access_key/deleteApiKey/rejected") {
                  toast.error(action.payload.Message as string);
                }
              })
            }
          >
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default KeyDeleteModal;
