import axios, { AxiosError } from "axios";
import { getStorage } from "../utils/helper";
export const $ApiAuthBaseURL: string = "https://signapi.djib.io/api/v1";
export const ApiAuthBaseURL: string = "https://signapi.djib.io/ipfs/api/v1";
export const ApiBaseURL: string = "https://signapi.djib.io/api/v1";

export const Fetch = axios.create({
  baseURL: ApiBaseURL,
});

export const AuthFetch = () =>
  axios.create({
    baseURL: ApiAuthBaseURL,

    headers: {
      Authorization: "Bearer " + getStorage("token"),
    },
  });
export const $AuthFetch = () =>
  axios.create({
    baseURL: $ApiAuthBaseURL,

    headers: {
      Authorization: "Bearer " + getStorage("token"),
    },
  });

export interface FetchResponse<T = any> {
  data: T;
  status: boolean;
  message: string;
  errors?: [];
  statusCode?: number | undefined;
}

export const FetchCatchResponse = (e: any): FetchResponse => {
  let message = "network error";
  let errors = [];
  let statusCode: number | undefined;
  if (e instanceof AxiosError) {
    console.log("ERROR", e);
    message = e.response?.data.message || e.response?.data.title || e.message;
    statusCode = e.response?.status;
    errors = e.response?.data.errors;
  } else {
    console.log("ERROR", e);
  }
  return {
    data: null,
    status: false,
    message,
    errors,
    statusCode,
  };
};
