import Combobox, { ComboboxOptionType } from "../combobox";
import { ReactComponent as DragIcon } from "../../assets/icons/drag-vertical.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete.svg";
import Listbox, { ListboxOptionType } from "../listbox";
import { WorkflowStep } from "./../../types/workflow";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  handleDeleteWorkflowStep,
  selectAllowedActions,
  selectDeliveryMethods,
  handleUpdateStepDelivery,
  handleUpdateStepAction,
  handleUpdateStepContact,
} from "../../store/workflowSlice";
import { handleShowContactsModal } from "../../store/contactsSlice";
import { Fragment, useState } from "react";
import { Transition } from "@headlessui/react";

const people = [
  { id: 1, name: "Wade Cooper" },
  { id: 2, name: "Arlene Mccoy" },
  { id: 3, name: "Devon Webb" },
  { id: 4, name: "Tom Cook" },
  { id: 5, name: "Tanya Fox" },
  { id: 6, name: "Hellen Schmidt" },
];
type RecipientDragItemType = {
  contacts: Array<ComboboxOptionType> | [];
  recipient: WorkflowStep;
  order?: number;
  initLoading?: boolean;
};

const RecipientDragItem = ({
  contacts,
  initLoading,
  order,
  recipient,
}: RecipientDragItemType) => {
  const deliveryMethods = useAppSelector(selectDeliveryMethods);
  const allowedActions = useAppSelector(selectAllowedActions);
  // const workflowInitLoading = useAppSelector(selectWorkflowInitLoading);
  const dispatch = useAppDispatch();

  // const [selected, setSelected] = useState(people[0]);
  // const [query, setQuery] = useState("");

  // const filteredPeople =
  //   query === ""
  //     ? people
  //     : people.filter((person) =>
  //         person.name
  //           .toLowerCase()
  //           .replace(/\s+/g, "")
  //           .includes(query.toLowerCase().replace(/\s+/g, ""))
  //       );

  return (
    <div className="relative flex justify-between items-center w-full border-2 p-3 pl-8 bg-white border-l-sky-400 border-l-4 rounded-l-md rounded-r-xl">
      <DragIcon className="w-5 h-5 absolute left-1 text-slate-600 hover:text-slate-800" />
      <div className="flex w-full flex-col md:flex-row md:justify-between items-start gap-4">
        <div className="w-full md:w-1/2">
          <span className="text-sm text-gray-600">
            Name <span className=" text-red-500 text-sm">*</span>
          </span>
          <Combobox
            options={contacts}
            loading={initLoading || false}
            selectedValue={recipient.ContactId}
            onChange={async (option: ComboboxOptionType) => {
              console.log(option);
              dispatch(
                handleUpdateStepContact({
                  stepId: recipient.Id,
                  contactId: option.value,
                })
              );
            }}
          />
          {/* <Combobox value={selected} onChange={setSelected}>
            <div className="relative mt-1">
              <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                <Combobox.Input
                  className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                  displayValue={(person: any) => person.name}
                  onChange={(event) => setQuery(event.target.value)}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
                </Combobox.Button>
              </div>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery("")}
              >
                <Combobox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {filteredPeople.length === 0 && query !== "" ? (
                    <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                      Nothing found.
                    </div>
                  ) : (
                    filteredPeople.map((person) => (
                      <Combobox.Option
                        key={person.id}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-10 pr-4 ${
                            active ? "bg-teal-600 text-white" : "text-gray-900"
                          }`
                        }
                        value={person}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? "font-medium" : "font-normal"
                              }`}
                            >
                              {person.name}
                            </span>
                            {selected ? (
                              <span
                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                  active ? "text-white" : "text-teal-600"
                                }`}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Combobox.Option>
                    ))
                  )}
                </Combobox.Options>
              </Transition>
            </div>
          </Combobox> */}
          <span
            className="text-sm text-gray-500 hover:text-sky-500 cursor-pointer"
            onClick={() => dispatch(handleShowContactsModal())}
          >
            show contacts
          </span>
        </div>
        <div className="w-full md:w-1/2">
          <div className="flex gap-1">
            <div className="w-5/12">
              <Listbox
                options={allowedActions.map(
                  (d) =>
                    ({
                      label: d.Name,
                      value: d.Value,
                    } as ListboxOptionType)
                )}
                selectedValue={recipient?.ActionType}
                firstSelect={true}
                onChange={(option: ListboxOptionType) => {
                  dispatch(
                    handleUpdateStepAction({
                      actionId: option.value,
                      stepId: recipient.Id,
                    })
                  );
                }}
              />
            </div>
            <div className="w-5/12">
              <Listbox
                options={deliveryMethods.map(
                  (a) =>
                    ({
                      label: a.Name,
                      value: a.Value,
                    } as ListboxOptionType)
                )}
                firstSelect={true}
                selectedValue={recipient?.Deliverymethod}
                onChange={(option: ListboxOptionType) =>
                  dispatch(
                    handleUpdateStepDelivery({
                      stepId: recipient.Id,
                      deliveryId: option.value,
                    })
                  )
                }
              />
            </div>
            <div className="w-2/12 flex items-center justify-end">
              <DeleteIcon
                className="w-6 h-6 text-slate-600 hover:text-slate-800 cursor-pointer hover:bg-sky-100 p-1 rounded-lg"
                onClick={() => dispatch(handleDeleteWorkflowStep(recipient.Id))}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipientDragItem;
