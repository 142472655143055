export default function LogoText() {
  return (
    <span>
      <span className="text-3xl font-bold text-[#394149] tracking-tighter">
        Djib
      </span>
      <span className="text-[#595959] font-semibold ml-1">IPFS</span>
    </span>
  );
}
