import Loading from "../loading";
import Modal, { ModalContainerProps } from "./modal";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  createFolderAsync,
  handleChangeInputValue,
  handleClearInputValue,
  handleHideConflictModal,
  selectCreateFolderLoading,
  selectInputValue,
} from "../../store/createFolderSlice";
import { selectBreadCrumbItems } from "../../store/breadCrumbSlice";
import { UrlConvertor, parentFinder } from "../../utils/helper";
import { fetchFiles } from "../../store/filesSlice";
import { useEffect, useState } from "react";
import { getPhotoApi } from "../../api/photoApi";
export type FileViewerProps = ModalContainerProps;

export function PhotoModal({
  show,
  onClose,
  file,
  photoLoading,
  setPhotoLoading,
}: FileViewerProps) {
  useEffect(() => {
    file &&
      getPhotoApi(file).then(() => {
        setPhotoLoading && setPhotoLoading(false);
      });
  }, []);

  return (
    <Modal
      title={""}
      modalClassName="w-full lg:w-[600px] bg-slate-100"
      containerStyle={{ alignItems: "flex-start", overflowY: "auto" }}
      show={show}
      onClose={onClose}
    >
      {photoLoading && (
        <div style={{ height: 150 }}>
          <Loading size={18} borderSize={2} />
        </div>
      )}

      {!photoLoading && (
        <>
          <img src={`https://ipfs.io/ipfs/${file?.public_cid}`}></img>
        </>
      )}
    </Modal>
  );
}
