import { AuthFetch, FetchCatchResponse, FetchResponse } from "./index";
import { FileType } from "../types/file";
import { Secret } from "../types/apiKey";

export async function getAccessKeysApi(): Promise<FetchResponse<Secret[]>> {
  try {
    const { data } = await AuthFetch().get(`/setting/access-keys`);
    return {
      data: data.Result,
      status: true,
      message: data.Message,
    };
  } catch (e) {
    return FetchCatchResponse(e);
  }
}

export async function deleteAccessKeyApi(keyId: number) {
  try {
    const { data } = await AuthFetch().delete(`/setting/access-keys/${keyId}`);
    // return {
    //   data: null,
    //   status: true,
    //   message: data.Message,
    // };

    return data;
  } catch (e) {
    return FetchCatchResponse(e);
  }
}

export async function createAccessKeyApi({ label }: { label: string }) {
  try {
    const { data } = await AuthFetch().post(`/setting/access-keys`, {
      label,
    });
    return data;
  } catch (e) {
    return FetchCatchResponse(e);
  }
}
