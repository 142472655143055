import { toast } from "react-hot-toast";
import { Contact, ContactsCreateDataType } from "../types/contacts";
import { CreateFolderDataType } from "../types/createFolder";
import { AuthFetch, FetchCatchResponse, FetchResponse } from "./index";

export async function getUserContactsApi(): Promise<
  FetchResponse<Array<Contact>>
> {
  try {
    const { data } = await AuthFetch().get(`/contact`);
    return {
      data: data.Result,
      status: true,
      message: data.Message,
    };
  } catch (e) {
    return FetchCatchResponse(e);
  }
}

export async function createFolderApi(formData: CreateFolderDataType) {
  try {
    const { data } = await AuthFetch().post(`/master/drive/mkdir`, formData);
    return {
      data: data.Result,
      status: true,
      message: data.Message,
    };
  } catch (e) {
    // return FetchCatchResponse(e);
    const { message, statusCode } = FetchCatchResponse(e);
    toast.error(message);
    if (statusCode === 409) {
    }

    return FetchCatchResponse(e);
  }
}

export async function deleteContactsApi(
  id: number
): Promise<FetchResponse<null>> {
  try {
    const { data } = await AuthFetch().delete(`/contact/${id}`);
    return {
      data: null,
      status: true,
      message: data.Message,
    };
  } catch (e) {
    return FetchCatchResponse(e);
  }
}
