import Loading from "../loading";
import Modal, { ModalContainerProps } from "./modal";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { ChangeEvent, useCallback, useState } from "react";
import Button from "../button";
import { ReactComponent as OverrideIcon } from "../../assets/icons/override.svg";
import { ReactComponent as KeepBothIcon } from "../../assets/icons/keep_both.svg";
import Input from "../input";
import {
  createFolderAsync,
  handleChangeInputValue,
  handleClearInputValue,
  handleHideConflictModal,
  selectCreateFolderLoading,
  selectInputValue,
} from "../../store/createFolderSlice";
import { selectBreadCrumbItems } from "../../store/breadCrumbSlice";
import { UrlConvertor, parentFinder } from "../../utils/helper";
import { fetchFiles } from "../../store/filesSlice";
export type FileViewerProps = ModalContainerProps;

export function ConflictModal({ show, onClose }: FileViewerProps) {
  const name = useAppSelector(selectInputValue);
  const breadCrumbItems = useAppSelector(selectBreadCrumbItems);
  const folderLoading = useAppSelector(selectCreateFolderLoading);

  const dispatch = useAppDispatch();

  const handleCreateFolder = (strategy: string) => {
    dispatch(
      createFolderAsync({
        name: name.toString(),
        strategy: strategy,
        path: parentFinder(breadCrumbItems),
      })
    ).then((action) => {
      if (action.type === "folder/create/fulfilled") {
        dispatch(handleHideConflictModal());
        dispatch(fetchFiles());
      }
    });
    dispatch(handleClearInputValue());
  };

  return (
    <Modal
      title={"The asset already exists"}
      modalClassName="w-full lg:w-[600px] bg-slate-100"
      containerStyle={{ alignItems: "flex-start", overflowY: "auto" }}
      show={show}
      onClose={onClose}
    >
      {folderLoading && (
        <div style={{ height: 100 }}>
          <Loading size={18} borderSize={2} />
        </div>
      )}
      {!folderLoading && (
        <>
          <div className="w-full mt-2 pl-2 flex flex-col gap-3 mb-3">
            What do you wish to do?
          </div>

          <div className="w-full mt-2 text-center flex items-center justify-between">
            <div className="flex items-center gap-3">
              <Button
                startIcon={<KeepBothIcon className="w-4 h-4" />}
                light={true}
                onClick={() => handleCreateFolder("keepBoth")}
              >
                Keep both
              </Button>
              <Button
                startIcon={<OverrideIcon className="w-4 h-4" />}
                light={true}
                onClick={() => handleCreateFolder("override")}
              >
                Override
              </Button>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
}

function NewFolderModal({ show, onClose }: FileViewerProps) {
  const folderLoading = useAppSelector(selectCreateFolderLoading);
  const breadCrumbItems = useAppSelector(selectBreadCrumbItems);
  const [name, setName] = useState("");
  const path = parentFinder(breadCrumbItems);
  const _path = UrlConvertor(path);

  const dispatch = useAppDispatch();

  const handleCreateNewFolder = () => {
    dispatch(handleChangeInputValue(name.toString()));
    dispatch(
      createFolderAsync({
        name: name.toString(),
        strategy: "None",
        path: _path,
      })
    ).then((action) => {
      setName("");
      onClose();
      if (action.payload === undefined) {
        dispatch(fetchFiles());
      }
    });
  };

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }, []);

  return (
    <Modal
      title={"Create New Folder"}
      modalClassName="w-full lg:w-[600px] bg-slate-100"
      containerStyle={{ alignItems: "flex-start", overflowY: "auto" }}
      show={show}
      onClose={() => {
        setName("");
        onClose();
      }}
    >
      {folderLoading && (
        <div style={{ height: 100 }}>
          <Loading size={18} borderSize={2} />
        </div>
      )}

      <>
        {!folderLoading && (
          <div className="w-full mt-2 text-center flex flex-col items-center justify-between gap-2">
            <Input
              type="text"
              value={name}
              placeholder="name"
              fullWidth={true}
              onChange={handleInputChange}
            />

            <Button
              isDisabled={name.length === 0}
              onClick={handleCreateNewFolder}
            >
              save folder
            </Button>
          </div>
        )}
      </>
    </Modal>
  );
}
export default NewFolderModal;
