import { useMemo, useRef } from "react";
import styles from "./../../styles/WorkflowList.module.scss";
import { ReactComponent as DownloadIcon } from "./../../assets/icons/download.svg";
import { ReactComponent as PlayIcon } from "./../../assets/icons/play.svg";
import { ReactComponent as DeleteIcon } from "./../../assets/icons/delete.svg";
import { ReactComponent as MoreIcon } from "./../../assets/icons/more.svg";
import IconButton from "../iconButton";
import classNames from "classnames";
import { FileType } from "../../types/file";
import { saveAs } from "file-saver";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  selectWorkflow,
  handleShowWorkflowStepsModal,
  handleShowEstimateModal,
  handleShowStatusModal,
} from "../../store/workflowSlice";
import { selectCredit } from "../../store/profileSlice";
import { Workflow } from "../../types/workflow";
import ProgressBar from "../progressBar";
import { convertDate, dateFormat, workflowProgress } from "../../utils/helper";
import { getPDF } from "../pdf/tools/maker";
import { ApiBaseURL } from "../../api";
import { toast } from "react-hot-toast";
import { Menu } from "@headlessui/react";
import FileIcon, { FileTypeType } from "../fileIcon";
import Highlighter from "react-highlight-words";
import { useSearch } from "../../providers/SearchProvider";

export type FileProps = {
  workflow: Workflow;
  onDeleteWorkflow?: (workflow: Workflow) => void;
  onError?: (e?: any) => void;
};

function WorkflowListItem({ workflow, onError, onDeleteWorkflow }: FileProps) {
  const ref = useRef(null);
  const dispatch = useAppDispatch();

  const { value: searchValue } = useSearch();

  const balance = useAppSelector(selectCredit);
  const { total, done, isDone } = useMemo(
    () => workflowProgress(workflow),
    [workflow]
  );
  const handleContinueWorkflow = () => {
    if (balance && balance > 0) {
      dispatch(selectWorkflow(workflow));
      dispatch(handleShowWorkflowStepsModal());
    } else {
      if (typeof onError === "function") onError("balance");
    }
  };

  const handleShowWorkflowStatus = () => {
    dispatch(selectWorkflow(workflow));
    dispatch(handleShowStatusModal());
  };

  const handlePayWorkflow = () => {
    if (balance && balance > 0) {
      dispatch(selectWorkflow(workflow));
      dispatch(handleShowEstimateModal());
    } else {
      if (typeof onError === "function") onError("balance");
    }
  };

  const handleDownloadFile = async () => {
    // `${ApiBaseURL}/workflow/${workflow.Id}/download?token=${workflow.OutputToken}`
    const fileData = await getPDF(
      `${ApiBaseURL}/public/download-output/${workflow.OutputToken}`
    );
    if (fileData && fileData.status) {
      const blob = fileData.blob;
      blob && saveAs(blob, workflow.FileName || fileData.filename);
    } else {
      toast.error("There is no WorkFlowOutPut file");
    }
  };

  const ItemActionsList = () => {
    return (
      <div className="flex gap-2 md:gap-2">
        {workflow.Status === 0 && (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleContinueWorkflow();
            }}
          >
            <PlayIcon
              data-tooltip-id="primary-tooltip"
              data-tooltip-content="Continue"
            />
          </IconButton>
        )}

        {workflow.Status === 1 && (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handlePayWorkflow();
            }}
          >
            <PlayIcon
              data-tooltip-id="primary-tooltip"
              data-tooltip-content="Pay"
            />
          </IconButton>
        )}
        {workflow.Status === 2 && (
          <IconButton className="text-slate-200">
            <DownloadIcon
              data-tooltip-id="primary-tooltip"
              data-tooltip-content="File will be ready to download as soone as all participants have signed the document"
            />
          </IconButton>
        )}
        {workflow.Status === 3 && (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleDownloadFile();
            }}
          >
            <DownloadIcon />
          </IconButton>
        )}

        {(workflow.Status === 0 || workflow.Status === 1) && (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              onDeleteWorkflow && onDeleteWorkflow(workflow);
            }}
          >
            <DeleteIcon className="w-6" />
          </IconButton>
        )}
      </div>
    );
  };
  return (
    <div
      className={classNames(styles.workflow)}
      ref={ref}
      // onClick={handleClick}
    >
      <div
        className={classNames(
          styles.name,
          "col-name",
          "text-sm md:text-base cursor-pointer"
        )}
        onClick={handleShowWorkflowStatus}
      >
        {/* <div className="hidden md:block">
          <FileIcon type={0} />
        </div> */}
        <p
          data-tooltip-id="right-tooltip"
          data-tooltip-content={workflow.FileName}
        >
          <Highlighter
            highlightClassName="YourHighlightClass"
            searchWords={[searchValue]}
            autoEscape={true}
            textToHighlight={workflow.FileName || ""}
          />
        </p>
      </div>

      <div
        className={classNames(
          styles.status,
          "col-status",
          "text-xs md:text-sm"
        )}
      >
        {workflow.Status === 0 && "Draft"}
        {workflow.Status === 1 && "Pending Payment"}
        {workflow.Status === 2 && "In Progress"}
        {workflow.Status === 3 && "Finalized"}
      </div>

      <div className={classNames(styles.progress, "col-progress")}>
        <ProgressBar total={total} done={done} draft={workflow.Status === 0} />
      </div>

      <div
        className={classNames(
          styles.status,
          "col-lastModify",
          "text-xs md:text-sm"
        )}
      >
        {dateFormat(convertDate(workflow.UpdatedAt))}
      </div>

      <div className={classNames(styles.action, "col-action")}>
        <div className="hidden md:block">
          <ItemActionsList />
        </div>
        <div className=" md:hidden">
          <Menu as="div" className="relative inline-block text-left">
            <Menu.Button>
              <IconButton>
                <MoreIcon />
              </IconButton>
            </Menu.Button>
            <Menu.Items className="absolute right-0 mt-2 z-10 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="px-3 py-2 ">
                <ItemActionsList />
              </div>
            </Menu.Items>
          </Menu>
        </div>
      </div>
    </div>
  );
}

export default WorkflowListItem;
