import { Link, useLocation } from "react-router-dom";
import Button from "./button";
import NewSecretModal from "./modals/newSecretModal";
import { useState } from "react";

export default function Settings() {
  const [secretModal, setSecretModal] = useState(false);
  const { pathname } = useLocation();
  const $pathname = pathname.split("/").filter((item) => !!item)[1];

  return (
    <>
      <div className="flex justify-between">
        <div className="flex gap-2 pt-4 px-4">
          <Link to={"/settings/profile"}>
            <Button
              {...{
                [$pathname === "profile" ? "light" : "extraLight"]: true,
              }}
              style={{ fontSize: "1rem" }}
            >
              Personal account
            </Button>
          </Link>

          <Link to={"/settings/access"}>
            <Button
              {...{
                [$pathname === "access" ? "light" : "extraLight"]: true,
              }}
              style={{ fontSize: "1rem" }}
            >
              Access key
            </Button>
          </Link>
        </div>
        {$pathname === "access" && (
          <div className="btns flex gap-2 pt-4 px-8">
            <Button onClick={() => setSecretModal(true)}>Create secret</Button>
          </div>
        )}
      </div>
      {
        <NewSecretModal
          onClose={() => setSecretModal(false)}
          show={secretModal}
        />
      }
    </>
  );
}
