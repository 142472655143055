import Loading from "../loading";
import Modal, { ModalContainerProps } from "./modal";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { ChangeEvent, useCallback, useState } from "react";
import Button from "../button";
import Input from "../input";
import {
  createApiKey,
  fetchApiKeys,
  selectKeyLoading,
} from "../../store/accessKeySlice";
import { toast } from "react-hot-toast";
export type FileViewerProps = ModalContainerProps;

function NewSecretModal({ show, onClose }: FileViewerProps) {
  const keyLoading = useAppSelector(selectKeyLoading);
  const [name, setName] = useState("");

  const dispatch = useAppDispatch();

  const handleCreateAccessKey = () => {
    dispatch(
      createApiKey({
        label: name.toString(),
      })
    ).then((action) => {
      setName("");
      onClose();
      if (action.meta.requestStatus === "fulfilled") {
        dispatch(fetchApiKeys());
        toast.success(action.payload.Message as string);
      }
    });
  };

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }, []);

  return (
    <Modal
      title={"Create New Secret"}
      modalClassName="w-full lg:w-[600px] bg-slate-100"
      containerStyle={{ alignItems: "flex-start", overflowY: "auto" }}
      show={show}
      onClose={() => {
        setName("");
        onClose();
      }}
    >
      {keyLoading && (
        <div style={{ height: 100 }}>
          <Loading size={18} borderSize={2} />
        </div>
      )}
      <>
        {!keyLoading && (
          <div className="w-full mt-2 text-center flex flex-col items-center justify-between gap-2">
            <Input
              type="text"
              value={name}
              placeholder="name"
              fullWidth={true}
              onChange={handleInputChange}
            />

            <Button
              isDisabled={name.length === 0}
              onClick={handleCreateAccessKey}
            >
              Create
            </Button>
          </div>
        )}
      </>
    </Modal>
  );
}
export default NewSecretModal;
