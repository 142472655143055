import { AuthFetch, FetchCatchResponse, FetchResponse } from "./index";
import { FileType } from "../types/file";

export async function getExportApi(file: FileType): Promise<FetchResponse> {
  const path = file.path.replaceAll("/", "%2F");
  try {
    const { data } = await AuthFetch().get(
      `/master/drive/export?isFolder=${file.is_folder}&path=${path}`,
      {
        headers: {
          responseType: "blob",
        },
      }
    );

    const url = window.URL.createObjectURL(
      new Blob([JSON.stringify(data.Result)], {
        type: "application/x-ndjson",
      })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${file.file_name}_${new Date(file.created_at * 1000)
        .toLocaleDateString("en-CA")
        .replaceAll("-", "")}_export.json`
    );
    link.click();
    link.remove();
    return {
      data: data.Result,
      status: true,
      message: data.Message,
    };
  } catch (e) {
    return FetchCatchResponse(e);
  }
}
