import { PaginationApiType } from "../types";
import {
  DeliveryMethod,
  AllowedAction,
  WorkflowCreateApiResponse,
  Workflow,
  WorkflowStepApiType,
  SignPositionType,
  StepDataType,
} from "../types/workflow";
import {
  $AuthFetch,
  AuthFetch,
  FetchCatchResponse,
  FetchResponse,
} from "./index";

export async function getWorkflowApi(
  id: number
): Promise<FetchResponse<Workflow>> {
  try {
    const { data } = await $AuthFetch().get(`/workflow/${id}`);
    return {
      data: data.Result,
      status: true,
      message: data.Message,
    };
  } catch (e) {
    return FetchCatchResponse(e);
  }
}

export async function getWorkflowsApi(
  pageNumber: number = 1,
  pageSize: number = 10
): Promise<
  FetchResponse<{ workflows: Array<Workflow>; pagination: PaginationApiType }>
> {
  try {
    const { data } = await $AuthFetch().get(
      `/workflow?pageNumber=${pageNumber}&pageSize=${pageSize}`
    );
    return {
      data: { workflows: data.Result, pagination: data.Pagination },
      status: true,
      message: data.Message,
    };
  } catch (e) {
    return FetchCatchResponse(e);
  }
}

export async function getDeliveryMethodsApi(): Promise<
  FetchResponse<Array<DeliveryMethod>>
> {
  try {
    const { data } = await $AuthFetch().get(`/public/delivery-method`);
    return {
      data: data.Result,
      status: true,
      message: data.Message,
    };
  } catch (e) {
    return FetchCatchResponse(e);
  }
}

export async function getAllowedActionsApi(): Promise<
  FetchResponse<Array<AllowedAction>>
> {
  try {
    const { data } = await $AuthFetch().get(`/public/allowed-actions`);
    return {
      data: data.Result,
      status: true,
      message: data.Message,
    };
  } catch (e) {
    return FetchCatchResponse(e);
  }
}

export async function createWorkflowApi(
  fileId: number
): Promise<FetchResponse<WorkflowCreateApiResponse>> {
  try {
    const { data } = await $AuthFetch().post(`/workflow`, { FileId: fileId });
    return {
      data: data.Result,
      status: true,
      message: data.Message,
    };
  } catch (e) {
    return FetchCatchResponse(e);
  }
}

export async function updateWorkflowApi({
  id,
  steps,
}: {
  id: number;
  steps: Array<WorkflowStepApiType>;
}): Promise<FetchResponse<Workflow>> {
  try {
    const { data } = await $AuthFetch().put(`/workflow/${id}`, [...steps]);
    return {
      data: data.Result,
      status: true,
      message: data.Message,
    };
  } catch (e) {
    return FetchCatchResponse(e);
  }
}

export async function updateWorkflowStepApi({
  workflowId,
  stepId,
  steps,
}: {
  workflowId: number;
  stepId: number;
  steps: Array<Omit<SignPositionType, "Id">>;
}): Promise<FetchResponse<Workflow>> {
  try {
    const { data } = await $AuthFetch().put(
      `/workflow/${workflowId}/workflow-steps/${stepId}`,
      [...steps]
    );
    return {
      data: data.Result,
      status: true,
      message: data.Message,
    };
  } catch (e) {
    return FetchCatchResponse(e);
  }
}

export async function deleteWorkflowApi({
  workflowId,
}: {
  workflowId: number;
}): Promise<FetchResponse<null>> {
  try {
    const { data } = await $AuthFetch().delete(`/workflow/${workflowId}`);
    return {
      data: data.Result,
      status: true,
      message: data.Message,
    };
  } catch (e) {
    return FetchCatchResponse(e);
  }
}

export async function estimateApi({
  workflowId,
}: {
  workflowId: number;
}): Promise<FetchResponse<number>> {
  try {
    const { data } = await $AuthFetch().get(`/workflow/${workflowId}/estimate`);
    return {
      data: data.Result,
      status: true,
      message: data.Message,
    };
  } catch (e) {
    return FetchCatchResponse(e);
  }
}

export async function submitWorkflowApi({
  workflowId,
}: {
  workflowId: number;
}): Promise<FetchResponse<number>> {
  try {
    const { data } = await $AuthFetch().post(`/workflow/${workflowId}/submit`);
    return {
      data: data.Result,
      status: true,
      message: data.Message,
    };
  } catch (e) {
    return FetchCatchResponse(e);
  }
}

export async function getStepApi({
  token,
}: {
  token: string;
}): Promise<FetchResponse<StepDataType>> {
  try {
    const { data } = await $AuthFetch().get(`/signature?token=${token}`);
    return {
      data: data.Result,
      status: true,
      message: data.Message,
    };
  } catch (e) {
    return FetchCatchResponse(e);
  }
}

export async function signApi(formData: any): Promise<FetchResponse<any>> {
  try {
    const { data } = await $AuthFetch().post(`/signature`, formData);
    return {
      data: data.Result,
      status: true,
      message: data.Message,
    };
  } catch (e) {
    return FetchCatchResponse(e);
  }
}
