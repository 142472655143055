import { useRef, useEffect, useState } from "react";
import styles from "./../styles/ApiKey.module.scss";
import { ReactComponent as CopyToClipboardIcon } from "./../assets/icons/copy.svg";
import { ReactComponent as DeleteIcon } from "./../assets/icons/delete.svg";
import IconButton from "./iconButton";
import classNames from "classnames";
import { FileType } from "../types/file";
import { useAppDispatch, useAppSelector } from "../hooks";
import { useSearch } from "../providers/SearchProvider";
import {
  selectBreadCrumbItems,
  updateBreadCrumb,
} from "../store/breadCrumbSlice";
import { fetchFiles } from "../store/filesSlice";
import { Secret } from "../types/apiKey";
import { toast } from "react-hot-toast";
import { deleteApiKey, fetchApiKeys } from "../store/accessKeySlice";
import KeyDeleteModal from "./modals/keyDeleteModal";

// export type FileProps = {
//   is_folder: boolean;
//   name: string;
//   extension: string | null;
//   size: number;
//   file: FileType;
//   createdAt: number;
//   onOpenFile?: (file: FileType) => void;
//   onDeleteFile?: (file: FileType) => void;
//   onOpenError?: () => void;
// };

function ApiKey({
  keyItem,
}: // name,
// is_folder,
// extension,
// size,
// file,
// createdAt,
// onOpenFile,
// onOpenError,
// onDeleteFile,
{
  keyItem: Secret;
}) {
  const ref = useRef(null);
  const dispatch = useAppDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  // const balance = useAppSelector(selectCredit);
  const breadCrumbItems = useAppSelector(selectBreadCrumbItems);
  const { value: searchValue } = useSearch();
  // const handleCreateWorkflow = () => {
  //   if (balance && balance > 0) {
  //     dispatch(createWorkflowAsync({ fileId: file.Id })).then(() => {
  //       dispatch(selectFile({ file }));
  //     });
  //   } else {
  //     if (typeof onOpenError === "function") onOpenError();
  //   }
  // };
  function handleFile(file: FileType, e: any) {
    // if (file?.is_folder) {
    //   setBreadCrumbItems([
    //     ...breadCrumbItems,
    //     { title: item?.file_name, id: item?._id },
    //   ]);
    // }
    e.preventDefault();

    // if(file.isFolder){
    dispatch(updateBreadCrumb(file.file_name));
    dispatch(fetchFiles());

    // }
  }
  // useEffect(() => {
  //   if (breadCrumbItems.length >= 2) {
  //   }
  // }, [breadCrumbItems]);
  const handleCopyToClipboard = (key: string) => {
    navigator.clipboard.writeText(key);
    toast.success("Copied");
  };

  // const handleDeleteKey = (keyId: number) => {
  //   dispatch(deleteApiKey({ keyId })).then((action) => {
  //     if (action.meta.requestStatus === "fulfilled") {
  //       dispatch(fetchApiKeys());
  //     }
  //   });
  // };

  return (
    <div
      className={classNames(styles.api)}
      ref={ref}
      // onDoubleClick={(e) => handleFile(file, e)}
    >
      <div className={classNames(styles.name, "col-name")}>
        {/* <FileIcon
          type={
            is_folder
              ? "folder"
              : (extToType(
                  extension ||
                    file.file_name.split(".")[1] ||
                    file.content_type?.split("/")[1] ||
                    ""
                ) as FileTypeType)
          }
        /> */}
        {keyItem.Label}
        {/* <p>
          <Highlighter
            highlightClassName="YourHighlightClass"
            searchWords={[searchValue]}
            autoEscape={true}
            textToHighlight={`${name}
            ${extension ? `.${extension}` : ""}`}
          />
        </p> */}
      </div>

      <div className={classNames(styles.name, "col-secret")}>
        {/* {size !== 0
          ? humanizeSize(Number(size), { fractionDigits: 2 }).join("B")
          : ""} */}
        {/* {keyItem.KeyValue} */}
        ********************************
      </div>

      {/* <div className={classNames(styles.date, "col-lastModified")}> */}
      {/* {dateConvertor(createdAt)} */}
      {/* Jul 27, 2023 */}
      {/* </div> */}

      <div className={classNames(styles.action, "col-action")}>
        <div className="flex gap-2">
          <IconButton
            // onClick={(e) => {
            //   e.stopPropagation();
            //   onDeleteFile && onDeleteFile(file);
            // }}
            onClick={(e) => {
              e.stopPropagation();
              handleCopyToClipboard(keyItem.KeyValue);
            }}
          >
            <CopyToClipboardIcon className="w-7 h-7" />
          </IconButton>

          <IconButton
            onClick={() => {
              setShowDeleteModal(true);
            }}
          >
            <DeleteIcon className="w-6 h-6" />
          </IconButton>

          {/* <IconButton
            onClick={(e) => {
              e.stopPropagation();
              // onOpenWorkflow && onOpenWorkflow(file);
              handleCreateWorkflow();
            }}
          >
            <PlusIcon />
          </IconButton> */}
        </div>
      </div>
      <KeyDeleteModal
        keyId={keyItem.Id}
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
      />
    </div>
  );
}

export default ApiKey;
