import classNames from "classnames";
import styles from "./../styles/ApiKeyList.module.scss";
import File2 from "./file2";
import Skeleton from "./skeleton";
import ApiKey from "./apiKey";
import {
  fetchApiKeys,
  selectKeyLoading,
  selectSecretList,
} from "../store/accessKeySlice";
import { useAppDispatch, useAppSelector } from "../hooks";
import { useEffect } from "react";

export default function ApiKeyList() {
  const dispatch = useAppDispatch();
  const keyLoading = useAppSelector(selectKeyLoading);
  const keyList = useAppSelector(selectSecretList);

  useEffect(() => {
    dispatch(fetchApiKeys());
  }, []);

  return (
    <>
      <div className={styles.apiKeyList}>
        <>
          <div className={classNames(styles.header, "col-name")}>Name</div>
          <div className={classNames(styles.header, "col-secret")}>Secret</div>
          <div className={classNames(styles.header, "col-action")}></div>
        </>

        {keyLoading && (
          <>
            {Array.from(Array(18).keys()).map((i) => (
              <div
                key={`file-skeleton-${i}`}
                className="flex gap-2 py-4 px-2 h-s"
              >
                {(i + 1) % 3 === 1 && (
                  <>
                    <Skeleton width={150} height={30} />
                  </>
                )}
                {(i + 1) % 3 === 2 && (
                  <>
                    <Skeleton width={200} height={30} />
                  </>
                )}
                {(i + 1) % 3 === 0 && (
                  <>
                    <Skeleton width={30} height={30} />
                    <Skeleton width={30} height={30} />
                  </>
                )}
              </div>
            ))}
          </>
        )}

        {!keyLoading &&
          keyList?.map((keyItem) => (
            <ApiKey key={keyItem.Id} keyItem={keyItem} />
          ))}
      </div>
      {/* {!files?.length && !filesLoading && (
          <div className={styles.empty}>
            <EmptyState />
            <p className="text-2xl text-slate-300 mt-4 font-medium">
              Drop files here
            </p>
            <p className="text-sm text-slate-300">
              Or use “Upload file” button.
            </p>
          </div>
        )} */}
    </>
  );
}
