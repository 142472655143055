import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";
import { toast } from "react-hot-toast";
import { ApiKeysStateType, Secret } from "../types/apiKey";
import {
  createAccessKeyApi,
  deleteAccessKeyApi,
  getAccessKeysApi,
} from "../api/accessKeysApi";

const initialState: ApiKeysStateType = {
  secretList: [],
  loading: false,
  error: false,
};

export const fetchApiKeys = createAsyncThunk(
  "access_key/fetchApiKeys",
  async (_, thunkAPI) => {
    const res = await getAccessKeysApi();
    return res.data;
  }
);
export const deleteApiKey = createAsyncThunk(
  "access_key/deleteApiKey",
  async ({ keyId }: { keyId: number }, thunkAPI) => {
    const res = await deleteAccessKeyApi(keyId);
    return res;
  }
);
export const createApiKey = createAsyncThunk(
  "access_key/createApiKey",
  async ({ label }: { label: string }, thunkAPI) => {
    const res = await createAccessKeyApi({ label });
    return res;
  }
);

const accessKeySlice = createSlice({
  name: "access_key",
  initialState: initialState,
  reducers: {
    // setProfile(state, action) {
    //   state.body = action.payload;
    // },
    // clearProfile(state) {
    //   state.body = initialState.body;
    //   state.loading = initialState.loading;
    //   state.error = initialState.error;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchApiKeys.fulfilled, (state, action) => {
        state.secretList = action.payload;
        state.loading = false;
        state.error = false;
      })
      .addCase(fetchApiKeys.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchApiKeys.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });

    builder
      .addCase(deleteApiKey.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
      })
      .addCase(deleteApiKey.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(deleteApiKey.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });

    builder
      .addCase(createApiKey.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
      })
      .addCase(createApiKey.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(createApiKey.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const selectKeyLoading = (state: RootState) => state.accessKey.loading;
export const selectSecretList = (state: RootState) =>
  state.accessKey.secretList;

// export const { setProfile, clearProfile } = profileSlice.actions;

export default accessKeySlice.reducer;
