import { useLocation } from "react-router-dom";
import Settings from "../components/settings";
import ApiKeyList from "../components/apiKeyList";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchApiKeys } from "../store/accessKeySlice";
import { useAppDispatch } from "../hooks";

export default function SettingsAccess() {
  const { pathname } = useLocation();
  const $pathname = pathname.split("/").filter((item) => !!item)[1];
  const dispatch = useAppDispatch();

  return (
    <div>
      <div className={`text-base text-slate-700`}>
        <Settings />
      </div>
      <div className="pt-10">
        <ApiKeyList />
      </div>
    </div>
  );
}
