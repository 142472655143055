import { useDispatch } from "react-redux";
import { ReactComponent as SeparatorIcon } from "./../assets/icons/separator.svg";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  breadCrumbRedirect,
  selectBreadCrumbItems,
} from "../store/breadCrumbSlice";
import { fetchFiles } from "../store/filesSlice";
import breadCrumbStyles from "./../styles/BreadCrumb.module.scss";

export default function BreadCrumb() {
  const dispatch = useAppDispatch();
  const breadCrumbItems = useAppSelector(selectBreadCrumbItems);

  const ClickOnBreadcrumbItem = (breadCrumbId: string) => {
    if (breadCrumbItems[breadCrumbItems.length - 1].id === breadCrumbId) return;
    dispatch(breadCrumbRedirect(breadCrumbId));

    dispatch(fetchFiles());
  };

  return (
    <div className={breadCrumbStyles.breadcrumb}>
      <ul>
        {breadCrumbItems.length === 1 ? (
          <li className="text-slate-800 text-xl font-medium">My Drive</li>
        ) : (
          breadCrumbItems.map((item, index) => (
            <div key={item.id}>
              <li onClick={() => ClickOnBreadcrumbItem(item.id)}>
                {item.title}
              </li>
              {index < breadCrumbItems.length - 1 && (
                <span>
                  <SeparatorIcon />
                </span>
              )}
            </div>
          ))
        )}
      </ul>
    </div>
  );
}
