import { useEffect, useRef, useCallback } from "react";
import Button from "../components/button";
import { useAppDispatch, useAppSelector } from "../hooks";
import { fetchFiles } from "../store/filesSlice";
import { toast } from "react-hot-toast";
import { uploadApi } from "../api/filesApi";
import { useDropzone } from "react-dropzone";
import classNames from "classnames";
//style
import globalStyles from "./../styles/global.module.scss";
import buttonStyle from "../styles/Button.module.scss";
import FileList2 from "../components/fileList2";
import NewFolderModal, {
  ConflictModal,
} from "../components/modals/newFolderModal";
import {
  handleHideConflictModal,
  handleHideCreateFolderModal,
  handleShowCreateFolderModal,
  selectCreateFolderLoading,
  selectShowConflictModal,
  selectShowCreateFolderModal,
} from "../store/createFolderSlice";
import BreadCrumb from "../components/breadCrumb";
import { UrlConvertor, parentFinder } from "../utils/helper";
import { selectBreadCrumbItems } from "../store/breadCrumbSlice";

export default function HomePage() {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const loading = useAppSelector(selectCreateFolderLoading);
  const showCreateFolderModal = useAppSelector(selectShowCreateFolderModal);
  const showConflictModal = useAppSelector(selectShowConflictModal);
  const breadCrumb = useAppSelector(selectBreadCrumbItems);
  const url = parentFinder(breadCrumb);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchFiles());
  }, [dispatch]);

  const upload = useCallback(
    async (file: File, url: string) => {
      const _t = toast.loading(`uploading file`);
      const _url = UrlConvertor(url);
      try {
        await uploadApi(file, _url);
        toast.success(`upload completed`, {
          id: _t,
        });
        dispatch(fetchFiles());
      } catch (e) {
        toast.error(`Failed!`, {
          id: _t,
        });
      }
      try {
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      } catch (e) {}
    },
    [dispatch]
  );

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      upload(acceptedFiles[0], url);
    },
    [upload, url]
  );

  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: true,
  });

  const handleUploadFiles = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;
      if (files) {
        upload(files[0], url);
      }
    },
    [upload, url]
  );

  const handleUploadClick = useCallback(() => {
    if (fileInputRef.current) fileInputRef.current.click();
  }, []);

  return (
    <>
      <input
        type="file"
        // accept="application/pdf"
        hidden={true}
        ref={fileInputRef}
        multiple={true}
        onChange={handleUploadFiles}
      />

      <div className="flex sticky py-6 px-8 top-0 bg-white transition-all duration-500">
        <BreadCrumb />

        <div className="btns ml-auto flex gap-2 mr-3">
          <Button
            className={buttonStyle["new-folder-btn"]}
            onClick={() => dispatch(handleShowCreateFolderModal())}
          >
            New Folder
          </Button>
        </div>
        <div className="btns flex gap-2">
          <Button onClick={handleUploadClick}>Upload File</Button>
        </div>
      </div>
      <div
        {...getRootProps({
          className: classNames(globalStyles.homeContent, {
            [globalStyles.dragActive]: isDragActive,
          }),
        })}
      >
        <input {...getInputProps()} />
        <FileList2 />
      </div>
      <NewFolderModal
        show={showCreateFolderModal}
        onClose={() => dispatch(handleHideCreateFolderModal())}
      />
      <ConflictModal
        show={showConflictModal}
        onClose={() => dispatch(handleHideConflictModal())}
      />
    </>
  );
}
